import React from 'react'
import './resources/AuditionRequestForm.css'
import { SectionHeader } from '../../../../shared/SectionHeader/SectionHeader'
import { ServiceResponse } from '../../../../shared/ServiceResponse/ServiceResponse'
import { TextField } from '../../../../shared/Forms/TextField'
import { TextAreaField } from '../../../../shared/Forms/TextAreaField'
import { Validators } from '../../../../shared/Forms/types/Validators'
import { AuditionRequestFailureResponse } from './AuditionRequestFailureResponse'
import { AuditionRequestFormProps } from './types/AuditionRequestFormProps'
import { AuditionRequestFormState } from './types/AuditionRequestFormState'

export class AuditionRequestForm extends React.Component<AuditionRequestFormProps, AuditionRequestFormState> {
  _auditionRequestUrl = 'https://api.eaglemountainsymphony.org/audition'

  constructor(props: AuditionRequestFormProps) {
    super(props)
    this.state = {
      name: '',
      email: '',
      phone: '',
      instrument: '',
      experience: '',
      nameIsValid: false,
      emailIsValid: false,
      phoneIsValid: false,
      instrumentIsValid: false,
      experienceIsValid: false,
      requestSucceeded: true,
      responseIsError: false,
      responseTitle: '',
      responseMessage: '',
      isValid: true,
      showForm: true,
    }
    this.nameChanged = this.nameChanged.bind(this)
    this.emailChanged = this.emailChanged.bind(this)
    this.phoneChanged = this.phoneChanged.bind(this)
    this.instrumentChanged = this.instrumentChanged.bind(this)
    this.experienceChanged = this.experienceChanged.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  applicantIsValid() {
    return (
      this.state.nameIsValid &&
      this.state.phoneIsValid &&
      this.state.emailIsValid &&
      this.state.instrumentIsValid &&
      this.state.experienceIsValid
    )
  }

  nameChanged(value: string, isValid: boolean): void {
    this.setState({ name: value, nameIsValid: isValid })
  }

  emailChanged(value: string, isValid: boolean): void {
    this.setState({ email: value, emailIsValid: isValid })
  }

  phoneChanged(value: string, isValid: boolean): void {
    this.setState({ phone: value, phoneIsValid: isValid })
  }

  instrumentChanged(value: string, isValid: boolean): void {
    this.setState({ instrument: value, instrumentIsValid: isValid })
  }

  experienceChanged(value: string, isValid: boolean): void {
    this.setState({ experience: value, experienceIsValid: isValid })
  }

  handleSubmit(event: React.FormEvent): void {
    event.preventDefault()
    var applicant = {
      name: this.state.name,
      phone: this.state.phone,
      email: this.state.email,
      instrument: this.state.instrument,
      experience: this.state.experience,
    }
    if (this.applicantIsValid()) {
      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(applicant),
      }
      fetch(this._auditionRequestUrl, requestMetadata)
        .then((res) => res.json())
        .then(() => {
          this.setState({
            showForm: false,
            responseIsError: false,
            responseTitle: 'We received your audition request',
            responseMessage:
              'Thank you for your interest in becoming a part of the Eagle Mountain Symphony ' +
              'Orchestra. A representative of the orchestra will be in touch with you soon to ' +
              ' schedule an audition time. Please be sure to download the audition instruction ' +
              ' sheet for your instrument from the table below.',
          })
        })
        .catch(() => {
          this.setState({ requestSucceeded: false, showForm: false })
        })
    } else {
      this.setState({
        responseIsError: true,
        responseTitle: 'Did you mean to do that?',
        responseMessage: 'Please double-check that you filled out all fields correctly.',
      })
    }
  }

  render() {
    return (
      <div>
        <div className='center-page'>
          <SectionHeader title='Requesting an Audition' />
          {this.state.requestSucceeded ? (
            <ServiceResponse
              title={this.state.responseTitle}
              message={this.state.responseMessage}
              isError={this.state.responseIsError}
            />
          ) : (
            <AuditionRequestFailureResponse state={this.state} />
          )}
          {this.state.showForm ? (
            <form onSubmit={this.handleSubmit}>
              <TextField
                label='Name:'
                placeholder='Full name…'
                value={this.state.name}
                onChange={this.nameChanged}
                validator={Validators.stringExistsValidator}
              />
              <TextField
                label='Phone:'
                placeholder='Phone number…'
                value={this.state.phone}
                onChange={this.phoneChanged}
                validator={Validators.stringExistsValidator}
              />
              <TextField
                label='Email:'
                placeholder='Email address…'
                value={this.state.email}
                onChange={this.emailChanged}
                validator={Validators.emailValidator}
              />
              <TextField
                label='Instrument:'
                placeholder='Instrument…'
                value={this.state.instrument}
                onChange={this.instrumentChanged}
                validator={Validators.stringExistsValidator}
              />
              <TextAreaField
                label='Briefly describe your experience with your instrument:'
                placeholder='Describe your experience…'
                value={this.state.experience}
                onChange={this.experienceChanged}
                validator={Validators.stringExistsValidator}
              />
              <div className='vertical-space-20'></div>
              <button type='submit' className='center-page'>
                Submit
              </button>
            </form>
          ) : (
            ''
          )}
        </div>
        <div className='vertical-space-20'></div>
      </div>
    )
  }
}
