import React from 'react'
import './resources/InstrumentSection.css'
import { SymphonyMember } from './SymphonyMember'
import { InstrumentSectionProps } from './types/InstrumentSectionProps'

export const InstrumentSection: React.FunctionComponent<InstrumentSectionProps> = ({
  instrumentName,
  members,
}: InstrumentSectionProps) => (
  <div className='instrument-section'>
    <div className='instrument-header'>{instrumentName}</div>
    {members.map((member) => (
      <SymphonyMember
        key={member.member_id}
        member_id={member.member_id}
        member_name={member.member_name}
        instrument={member.instrument}
        is_section_leader={member.is_section_leader}
      />
    ))}
  </div>
)
