import React from 'react'
import bannerImage from './resources/bannerImage.png'
import './resources/Sponsors.css'
import { SponsorsProps } from './types/SponsorsProps'
import { SponsorsState } from './types/SponsorsState'
import { PageBanner } from '../../shared/PageBanner/PageBanner'
import { SectionHeader } from '../../shared/SectionHeader/SectionHeader'
import { Sponsor } from './components/Sponsor/Sponsor'
import { DonateButton } from '../../shared/DonateButton/DonateButton'

export class Sponsors extends React.Component<SponsorsProps, SponsorsState> {
  constructor(props: SponsorsProps) {
    super(props)
    this.state = {
      error: undefined,
      isLoaded: false,
      sponsors: [],
    }
  }
  componentDidMount() {
    fetch('https://api.eaglemountainsymphony.org/sponsors')
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            sponsors: result.Items,
          })
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          })
        }
      )
  }

  render() {
    const { error, isLoaded, sponsors } = this.state
    if (this.state.error) {
      return <div className='center-page warning'>Error: {error?.message}</div>
    } else if (!isLoaded) {
      return <div className='center-page'>Loading...</div>
    } else {
      return (
        <div>
          <PageBanner
            image={bannerImage}
            title='Give the gift of Music'
            subtitle='The EMSO depends on the generous contributions of its patrons and
                friends.
                Please help us to keep bringing quality music to our community by becoming a
                sponsor today.'
          />
          <SectionHeader
            title='Sponsorship Information'
            description='You play a critical role in bringing symphonic music to Eagle Mountain.  Without the support of our sponsors 
                        we simply could not make it happen.  To show our sincere thanks for your enabling contribution, we offer some perks based on 
                        your sponsorship level.  We have sponsorship levels to fit any budget.'
          />
          <div className='sponsorship-information'>
            <h2>Season Sponsorship ($5,000 and up)</h2>
            <ul>
              <li>50 tickets to a combination of concerts of your choice</li>
              <li>Full-page ad in all concert programs for the season</li>
              <li>Your logo featured on the program cover for the season</li>
              <li>Your logo featured on concert posters, postcards, and tickets</li>
              <li>Your logo featured in the concert program</li>
              <li>Recognition from the Stage at all concert performances</li>
              <li>Recognition on the website</li>
            </ul>
            <h2>Season Co-Sponsorship ($2,500-$4,999)</h2>
            <ul>
              <li>25 tickets to a combination of concerts of your choice</li>
              <li>Half-page ad in all concert programs for the season</li>
              <li>Your logo featured on program covers for the season</li>
              <li>Your logo featured on concert posters, postcards, and tickets</li>
              <li>Your logo featured in the concert program</li>
              <li>Recognition from the Stage at all concert performances</li>
              <li>Recognition on the website</li>
            </ul>
            <h2>Concert Sponsorship ($1000-$2,499)</h2>
            <ul>
              <li>10 tickets to the sponsored EMSO concert</li>
              <li>Full-page ad in the concert program</li>
              <li>Your logo featured on program cover</li>
              <li>Your logo featured on concert posters, postcards and tickets</li>
              <li>Your logo featured in the concert program</li>
              <li>Recognition from the Stage at the concert performance</li>
              <li>Recognition on the website</li>
            </ul>
            <h2>Concert Co-Sponsorship ($500 - $999)</h2>
            <ul>
              <li>5 tickets to sponsored concert</li>
              <li>Half-page ad in the concert program</li>
              <li>Your logo featured in the concert program</li>
              <li>Recognition from the Stage at the concert performance</li>
              <li>Recognition on the website</li>
            </ul>
            <h3 className='sponsorship-information'>Get started as an EMSO Sponsor</h3>
            <div className='sponsorship-information'>
              <DonateButton PaypalButtonId='4YQM58K7GZHG4' />
            </div>
            <p>&nbsp;</p>
            <h2>Musician Sponsorship (Any Amount)</h2>
            <ul>
              <li>Recognition on the website</li>
              <li>Spotlight of the sponsored musician in the concert program</li>
            </ul>
            <h3 className='sponsorship-information'>Sponsor your favorite musician</h3>
            <div className='sponsorship-information'>
              <DonateButton PaypalButtonId='XGVDK299WWEAJ' />
            </div>
            <p>&nbsp;</p>
          </div>
          <SectionHeader
            title='Thank You!'
            description='We could not be successful without the generous support of our sponsors and donors.'
          />
          <div className='sponsors-container'>
            {sponsors.length > 0 ? (
              sponsors.map((sponsor) => <Sponsor key={sponsor.sponsor_id} sponsor={sponsor} />)
            ) : (
              <h3 className='center-page'>Be the first to sponsor the EMSO!</h3>
            )}
          </div>
        </div>
      )
    }
  }
}
