import React from 'react'
import bannerImage from './resources/bannerImage.png'
import './resources/About.css'
import { PageBanner } from '../../shared/PageBanner/PageBanner'
import { BoardOfDirectors } from './components/BoardOfDirectors/BoardOfDirectors'
import { Symphony } from './components/Symphony/Symphony'

export const About: React.FunctionComponent = () => (
  <div>
    <div>
      <PageBanner image={bannerImage} title='We are the EMSO' subtitle='Great music is a team effort!' />
      <BoardOfDirectors />
      <Symphony />
    </div>
  </div>
)
