import React from 'react'
import './resources/BoardOfDirectors.css'
import { BoardMember } from './BoardMember'
import { SectionHeader } from '../../../../shared/SectionHeader/SectionHeader'
import { BoardMemberType } from './types/BoardMemberType'
import { BoardState } from './types/BoardState'
import { BoardProps } from './types/BoardProps'

export class BoardOfDirectors extends React.Component<BoardProps, BoardState> {
  constructor(props: BoardProps) {
    super(props)
    this.state = {
      error: undefined,
      isLoaded: false,
      boardMembers: [],
    }
  }

  componentDidMount() {
    fetch('https://api.eaglemountainsymphony.org/board')
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            boardMembers: result.Items,
          })
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          })
        }
      )
  }

  compareMembers(a: BoardMemberType, b: BoardMemberType): number {
    return a.position === 'President'
      ? -1
      : b.position === 'President'
      ? 1
      : a.position !== b.position
      ? a.position === 'Board Member'
        ? 1
        : b.position === 'Board Member'
        ? -1
        : b.position > a.position
        ? -1
        : 1
      : b.member_name > a.member_name
      ? -1
      : 1
  }

  render() {
    const { error, isLoaded, boardMembers } = this.state
    if (error) {
      return <div>Error: {error.message}</div>
    } else if (!isLoaded) {
      return <div>Loading...</div>
    } else {
      return (
        <div>
          <SectionHeader
            title='Meet the Board'
            description='Members of the Board of Directors are volunteers who work tirelessly to make the EMSO
                        successful, planning and printing concert programs, recruiting new musicians, fundraising, and promoting
                        the symphony in the community. Their dedicated work makes it possible for the symphony to continue to
                        bring great music to Eagle Mountain.'
          />
          <div className='row'>
            <div className='board-list'>
              {boardMembers.sort(this.compareMembers).map((item) => (
                <BoardMember
                  key={item.member_id}
                  member_id={item.member_id}
                  member_name={item.member_name}
                  position={item.position}
                  bio={item.bio}
                />
              ))}
            </div>
          </div>
        </div>
      )
    }
  }
}
