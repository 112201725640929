import logo from './resources/logo-full120.png'
import React from 'react'
import './resources/Menu.css'
import { MenuButton } from './MenuButton'
import { ImageButton } from '../../../../shared/ImageButton/ImageButton'
import { DonateButton } from '../../../../shared/DonateButton/DonateButton'

export const Menu: React.FunctionComponent = () => (
  <div className={'menubar'}>
    <ImageButton link='/' source={logo} alt='EMSO Logo' imageClass='logo' />
    <div className='menu-buttons-container'>
      <DonateButton PaypalButtonId='YBYDGNX7MRWF2' />
      <MenuButton title='Members' link='https://music.eaglemountainsymphony.org' />
      <MenuButton title='Concert Season' link='/' />
      <MenuButton title='Tickets' link='/tickets' />
      <MenuButton title='Sponsors' link='/sponsors' />
      <MenuButton title='About Us' link='/info' />
      <MenuButton title='Contact Us' link='/contact' />
    </div>
  </div>
)
