import React from 'react'
import './resources/SymphonyMember.css'
import { SymphonyMemberType } from './types/SymphonyMemberType'

export const SymphonyMember: React.FunctionComponent<SymphonyMemberType> = ({
  member_id,
  member_name,
  is_section_leader,
}: SymphonyMemberType) => (
  <div className='member' id={member_id}>
    <div className='member-info'>
      {member_name}
      {is_section_leader ? '*' : ''}
    </div>
  </div>
)
