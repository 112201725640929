import React from 'react'
import bannerImage from './resources/bannerImage.png'
import './resources/Audition.css'
import { PageBanner } from '../../shared/PageBanner/PageBanner'
import { SectionHeader } from '../../shared/SectionHeader/SectionHeader'
import { AuditionInstrument } from './components/AuditionInstrument/AuditionInstrument'
import { AuditionRequestForm } from './components/AuditionRequestForm/AuditionRequestForm'

export const Audition: React.FunctionComponent = () => (
  <div>
    <PageBanner
      image={bannerImage}
      title='Join the Symphony!'
      subtitle='Symphony musicians enjoy a challenging, but rewarding experience,
            building friendships and performing with some of the best artists in the community.'
    />
    <AuditionRequestForm />
    <SectionHeader title={'What to Expect at Your Audition'} />
    <div className={'row'}>
      Auditions are generally held on Friday evenings either before or during a regularly scheduled rehearsal.
      The section leader for your instrument and one of the conductors will listen to you play one or more
      major scales common to your instrument, and a short excerpt or two from a symphonic piece. The music and
      the requested scales to be prepared are included in the instruction sheet for your instrument below.
    </div>
    <div id='audition-instructions'>
      <SectionHeader
        title={'Instrument-Specific Audition Instructions'}
        description={'Choose the instruction sheet for your instrument from this list'}
      />
      <div className={'audition-instrument-section-title row'}>Strings</div>
      <div className={'audition-instrument-section row'}>
        <AuditionInstrument name={'Violin'} />
        <AuditionInstrument name={'Viola'} />
        <AuditionInstrument name={'Cello'} />
        <AuditionInstrument name={'Bass'} />
      </div>
      <div className={'audition-instrument-section-title row'}>Woodwinds</div>
      <div className={'audition-instrument-section row'}>
        <AuditionInstrument name={'Flute'} />
        <AuditionInstrument name={'Oboe'} />
        <AuditionInstrument name={'Clarinet'} />
        <AuditionInstrument name={'Bass Clarinet'} />
        <AuditionInstrument name={'Bassoon'} />
      </div>
      <div className={'audition-instrument-section-title row'}>Brass</div>
      <div className={'audition-instrument-section row'}>
        <AuditionInstrument name={'French Horn'} />
        <AuditionInstrument name={'Trumpet'} />
        <AuditionInstrument name={'Trombone'} />
        <AuditionInstrument name={'Bass Trombone'} />
        <AuditionInstrument name={'Tuba'} />
      </div>
    </div>
  </div>
)
