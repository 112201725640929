import React from 'react'
import { PayPalButton } from './types/PayPalButton'
import { PayPalOrderFormProps } from './types/PayPalOrderFormProps'
import { PayPalOrder } from './types/PayPalDataTypes'
import { OrderSummary } from './types/OrderSummary'

export class PayPalOrderForm extends React.Component<PayPalOrderFormProps> {
  constructor(props: PayPalOrderFormProps) {
    super(props)
    this.createOrder = this.createOrder.bind(this)
    this.onApprove = this.onApprove.bind(this)
  }
  subTotal(): number {
    let subtotal = 0
    this.props.tickets.forEach((ticket) => {
      subtotal += ticket.concert.family_price * ticket.family_quantity
      subtotal += ticket.concert.individual_price * ticket.individual_quantity
    })
    return subtotal
  }
  formatCurrency(value: number): string {
    // Create our number formatter.
    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })

    return formatter.format(value)
  }
  formatCurrencyNumberOnly(value: number): string {
    // Create our number formatter.
    let formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })

    return formatter.format(value)
  }
  createOrder(_data: any, actions: any) {
    //construct the order object from the available information
    let order: PayPalOrder = {
      intent: 'CAPTURE',
      application_context: {
        brand_name: 'Eagle Mountain Symphony Orchestra',
        shipping_preference: 'NO_SHIPPING',
        user_action: 'PAY_NOW',
      },
      purchase_units: [
        {
          items: [],
          amount: {
            currency_code: 'USD',
            value: '0.00',
            breakdown: {
              item_total: {
                currency_code: 'USD',
                value: '0.00',
              },
              discount: {
                currency_code: 'USD',
                value: '0.00',
              },
            },
          },
          description: this.props.discountPercent > 0 ? `Coupon applied: ${this.props.promoCode}` : '',
        },
      ],
    }
    let sub: number = 0
    this.props.tickets.forEach((ticket) => {
      if (ticket.individual_quantity > 0) {
        sub += ticket.individual_quantity * ticket.concert.individual_price
        order.purchase_units[0].items.push({
          name: `${ticket.concert.name} - EMSO Single Admission`,
          unit_amount: {
            currency_code: 'USD',
            value: this.formatCurrencyNumberOnly(ticket.concert.individual_price),
          },
          quantity: `${ticket.individual_quantity}`,
        })
      }
      if (ticket.family_quantity > 0) {
        sub += ticket.family_quantity * ticket.concert.family_price
        order.purchase_units[0].items.push({
          name: `${ticket.concert.name} - EMSO Family Pack`,
          unit_amount: {
            currency_code: 'USD',
            value: this.formatCurrencyNumberOnly(ticket.concert.family_price),
          },
          quantity: `${ticket.family_quantity}`,
        })
      }
    })
    let disc: number = this.props.discountPercent > 0 ? sub * this.props.discountPercent : 0
    let tot: number = sub - disc
    order.purchase_units[0].amount.value = `${this.formatCurrencyNumberOnly(tot)}`
    order.purchase_units[0].amount.breakdown.item_total.value = `${this.formatCurrencyNumberOnly(sub)}`
    order.purchase_units[0].amount.breakdown.discount.value = `${this.formatCurrencyNumberOnly(disc)}`

    return actions.order.create(order)
  }
  _randomString() {
    return Math.random().toString(36).substring(2, 15)
  }
  onApprove(_data: any, actions: any) {
    return actions.order.capture().then((response: any) => {
      let order: OrderSummary = {
        order_id: `${this._randomString()}-${this._randomString()}`,
        tickets: this.props.tickets,
        promoCode: this.props.promoCode,
        discountPercent: this.props.discountPercent,
        orderDetails: {
          id: response.id,
          status: response.status,
          payer: response.payer,
          order_time: response.create_time,
        },
      }
      this.props.onOrderComplete && this.props.onOrderComplete(order)
    })
  }
  render() {
    let subtotal = this.subTotal()
    let discount = this.props.discountPercent * subtotal
    let total = subtotal - discount
    return (
      <div>
        {this.props.discountPercent > 0 ? (
          <div className='right-align-items'>
            <div className='right'>
              Subtotal: <label className='money'>{this.formatCurrency(subtotal)}</label>
            </div>
            <div className='right'>
              Discount: <label className='money'>{this.formatCurrency(discount)}</label>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className='right'>
          Total: <label className='money'>{this.formatCurrency(total)}</label>
        </div>
        <PayPalButton createOrder={this.createOrder} onApprove={this.onApprove} />
      </div>
    )
  }
}
