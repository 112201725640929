import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Menu } from "./components/Menu/Menu";
import { CurrentProgram } from "../ConcertProgram/CurrentProgram";
import { Concerts } from "../Concerts/Concerts";
import { Tickets } from "../Tickets/Tickets";
import { Sponsors } from "../Sponsors/Sponsors";
import { About } from "../About/About";
import { Contact } from "../Contact/Contact";
import { Audition } from "../Audition/Audition";
import { Footer } from "./components/Footer/Footer";
import "./resources/App.css";

export const App: React.FunctionComponent = () => (
  <div>
    <Menu />

        <main>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Concerts />} />
                    <Route path="/concert-program" element={<CurrentProgram />} />
                    <Route path="/tickets" element={<Tickets />} />
                    <Route path="/sponsors" element={<Sponsors />} />
                    <Route path="/info" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/audition" element={<Audition />} />
                </Routes>
            </BrowserRouter>
        </main>

    <Footer />
  </div>
)
