import React from 'react'
import './resources/ImageButton.css'
import { ImageButtonProps } from './types/ImageButtonProps'

export const ImageButton: React.FunctionComponent<ImageButtonProps> = ({
  link,
  source,
  alt,
  imageClass,
}: ImageButtonProps) => (
  <a href={link}>
    <img className={imageClass} src={source} alt={alt} />
  </a>
)
