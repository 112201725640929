import React from 'react'
import './resources/Footer.css'
import facebookImage from './resources/webicon-facebook.png'

export const Footer: React.FunctionComponent = () => (
  <footer>
    <div className='footer-column left'>
      <h4 className='footer-heading'>Join the Symphony</h4>
      <div className='footer-rule'>
        <hr />
      </div>
      <p>
        Are you looking for an opportunity to develop your skills with fun and challenging music? We are
        always looking for talented musicians to join the orchestra.{' '}
        <a href='/audition'>Request an audition today!</a>
      </p>
    </div>
    <div className='footer-column right'>
      <h4 className='footer-heading'>Rehearsals</h4>
      <div className='footer-rule'>
        <hr />
      </div>
      <p>
        Mondays, 7-9 pm (Bring your own music stand)
        <br />@ Eagle Mountain Chamber of Commerce 
        <br />
        <a rel='noreferrer' href='https://www.google.com/maps/place/Eagle+Mountain+Chamber+of+Commerce/@40.379058,-111.973375,15z/data=!4m6!3m5!1s0x874d7b3956c92bd1:0x1c91494880e869b2!8m2!3d40.379058!4d-111.973375!16s%2Fg%2F11fm42l2jf?entry=ttu' target='_blank'>3688 E Campus Dr #101, Eagle Mountain, UT 84005</a>
      </p>
    </div>
    <div className='footer-column right'>
      <h4 className='footer-heading'>Contacts</h4>
      <div className='footer-rule'>
        <hr />
      </div>
      <a href='mailto:eaglemountainsymphony@gmail.com'>eaglemountainsymphony&#64;gmail.com</a>
      <br />
      <a
        className='social-image'
        href='https://www.facebook.com/EagleMountainSymphonyOrchestra/'
        target='_blank'
        rel='noreferrer'>
        <img src={facebookImage} alt='Visit us on Facebook' />
      </a>
    </div>
  </footer>
)
