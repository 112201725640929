import React from 'react'
import './resources/AuditionInstrument.css'
import { AuditionInstrumentProps } from './types/AuditionInstrumentProps'

export const AuditionInstrument: React.FunctionComponent<AuditionInstrumentProps> = ({
  name,
}: AuditionInstrumentProps) => (
  <div className={'audition-instrument'}>
    <a
      href={`/audition-music/${name.toLowerCase().replace(' ', '-')}-audition-instructions.pdf`}
      target='_blank'
      rel={'noreferrer'}>
      {name}
    </a>
  </div>
)
