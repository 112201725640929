import React from 'react'
import './resources/Forms.css'
import { TextAreaProps } from './types/TextAreaProps'
import { TextFieldState } from './types/TextFieldState'

export class TextAreaField extends React.Component<TextAreaProps, TextFieldState> {
  constructor(props: TextAreaProps) {
    super(props)
    this.state = {
      value: props.value ? props.value : '',
    }

    this.valueChanged = this.valueChanged.bind(this)
  }

  valueChanged(event: React.FormEvent<HTMLTextAreaElement>): void {
    this.setState({ value: event.currentTarget.value })
    this.props.onChange &&
      this.props.onChange(event.currentTarget.value, this.isValid(event.currentTarget.value))
  }

  isValid(value: string): boolean {
    return !this.props.validator || this.props.validator.test(value)
  }

  render() {
    return (
      <div className='field-layout'>
        <label
          htmlFor='input-field'
          className={this.isValid(this.state.value) ? 'form-input-label' : 'form-input-label warning'}>
          {this.props.label}
        </label>
        <textarea
          id='input-field'
          rows={this.props.rows}
          placeholder={this.props.placeholder}
          cols={this.props.cols}
          onChange={this.valueChanged}
          value={this.state.value}
        />
      </div>
    )
  }
}
