import React from 'react'
import bannerImage from './resources/bannerImage.png'
import './resources/Tickets.css'
import { PageBanner } from '../../shared/PageBanner/PageBanner'
import { TicketList } from './components/Tickets/TicketList'
import { SectionHeader } from '../../shared/SectionHeader/SectionHeader'
import { TicketsProps } from './types/TicketsProps'
import { TicketsState } from './types/TicketsState'
import { ConcertData } from '../Concerts/types/ConcertData'
import { PayPalOrderForm } from './components/PayPal/PayPalOrderForm'
import { TicketData } from './types/TicketData'
import { PromoCodeData } from './components/PromoCodes/types/PromoCodeData'
import { PromoCode } from './components/PromoCodes/PromoCode'
import { OrderSummary } from './components/PayPal/types/OrderSummary'

export class Tickets extends React.Component<TicketsProps, TicketsState> {
  constructor(props: TicketsProps) {
    super(props)
    this.state = {
      error: undefined,
      isLoaded: false,
      concerts: [],
      tickets: [],
      promoCode: '',
      discountPercent: 0.0,
      orderCompleted: false,
      orderEmail: '',
    }
    this.updateTickets = this.updateTickets.bind(this)
    this.updatePromoCode = this.updatePromoCode.bind(this)
    this.handleOrderComplete = this.handleOrderComplete.bind(this)
  }
  componentDidMount() {
    fetch('https://api.eaglemountainsymphony.org/concerts')
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            concerts: result.Items.sort(this.compareConcerts).filter(this.isForSale),
          })
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          })
        }
      )
  }

  compareConcerts(a: ConcertData, b: ConcertData): number {
    return new Date(b.date) > new Date(a.date) ? 1 : -1
  }
  isForSale(concert: ConcertData): boolean {
    let today = new Date(new Date(Date.now()).setHours(0, 0, 0))
    today.setDate(today.getDate() - 1)
    let concertDate = new Date(concert.date)
    return concertDate >= today && concert.individual_price > 0
  }
  updateTickets(tickets: TicketData[]): void {
    this.setState({ tickets: tickets })
  }
  updatePromoCode(promoCode: PromoCodeData): void {
    if (promoCode && promoCode.is_valid) {
      this.setState({ promoCode: promoCode.promo_code_id, discountPercent: promoCode.discount_percent })
    } else {
      this.setState({ promoCode: '', discountPercent: 0 })
    }
  }
  handleOrderComplete(order: OrderSummary): void {
    // Send to API for storing in the database and sending emailed tickets
    const requestOptions: RequestInit = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(order),
    }
    fetch('https://api.eaglemountainsymphony.org/ticket-orders', requestOptions).then(
      () => {
        // Order succeeded, and we successfully stored it in our database and sent a ticket email
        // Show the thank you page.
        this.setState({ orderCompleted: true, orderEmail: order.orderDetails.payer.email_address })
      },
      (error) => {
        this.setState({ error: error })
        // Order was successful, but we failed to store it in our database or send the ticket email.
        // Show the thank you page.
      }
    )
  }

  render() {
    const { error, isLoaded, concerts, orderCompleted } = this.state
    var header: JSX.Element = (
      <PageBanner
        image={bannerImage}
        title='Purchase Tickets'
        subtitle="The Symphony has been working hard to prepare an exciting evening of
                    quality symphonic music. Bring the family. We'll save you a seat."
      />
    )
    var body: JSX.Element
    if (error) {
      body = (
        <div>
          <SectionHeader title='Whoops... Something went wrong' />
          <div className='row center-page'>
            The following error occurred: {error.message}. We are very sorry for the inconvenience. Please
            send an email to{' '}
            <a href='mailto:eaglemountainsymphony@gmail.com?Subject=Ticket%20Order%20Error'>
              eaglemountainsymphony@gmail.com
            </a>{' '}
            so that we can help you recover your tickets.
          </div>
        </div>
      )
    } else if (!isLoaded) {
      body = (
        <div>
          <SectionHeader title='Purchase tickets to the Symphony' />
          <div className='row center-page'>Loading the list of upcoming concerts... Please wait...</div>
        </div>
      )
    } else if (orderCompleted) {
      body = (
        <div>
          <SectionHeader title='Thank you for your ticket order' />
          <div className='row center-page'>
            Your order has processed successfully, and your tickets are being emailed to{' '}
            <strong>{this.state.orderEmail}</strong>. Thank you for your generous support of the Eagle
            Mountain Symphony Orchestra. See you at the concert!
          </div>
        </div>
      )
    } else {
      body = (
        <div>
          <SectionHeader title='Purchase tickets to the Symphony' />
          <div className='ticket-form'>
            <TicketList concerts={concerts} onChange={this.updateTickets} />
            {concerts.length > 0 ? (
              <div>
                <PromoCode onChange={this.updatePromoCode} />
                <PayPalOrderForm
                  tickets={this.state.tickets}
                  promoCode={this.state.promoCode}
                  discountPercent={this.state.discountPercent}
                  onOrderComplete={this.handleOrderComplete}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      )
    }
    return (
      <div>
        {header}
        {body}
      </div>
    )
  }
}
