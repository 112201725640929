import React from "react";
import "./resources/ConcertProgram.css";
import { ConcertData } from "../Concerts/types/ConcertData";
import { ConcertProgramProps } from "./types/ConcertProgramProps";
import { ConcertProgramState } from "./types/ConcertProgramState";
import { Concert } from "./types/Concert";

export class CurrentProgram extends React.Component<ConcertProgramProps, ConcertProgramState> {
    constructor(props: ConcertProgramProps) {
        super(props);
        this.state = {
            error: undefined,
            isLoaded: false,
            currentConcert: undefined
        };
    }
    componentDidMount() {
        fetch("https://api.eaglemountainsymphony.org/concerts")
            .then(res => res.json())
            .then(
                (result) => {
                    var current: ConcertData | undefined = undefined;
                    result.Items.forEach ((concert: ConcertData) => {
                        if(this.isCurrentConcert(concert)) {
                            if((current === undefined) || (new Date(current.date) < new Date(concert.date))){
                                current = concert;
                            }
                        }
                    });
                    this.setState({
                        isLoaded: true,
                        currentConcert: current
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    isCurrentConcert(concert: ConcertData): boolean {
        let tomorrow = new Date(Date.now());
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);
        let concertDate = new Date(concert.date);
        concertDate.setHours(0, 0, 0, 0);
        return (concertDate <= tomorrow);
    }

    render() {
        const { error, isLoaded, currentConcert } = this.state;
        if (error) {
            return <div className="center-page warning">Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div className="center-page">Loading...</div>;
        } else if (currentConcert === undefined) {
            return <div className="center-page warning">Error: No concert is currently active.</div>;
        } else {
            return (
                <div>
                    <Concert
                        concert_id={currentConcert.concert_id}
                        name={currentConcert.name}
                        brief={currentConcert.brief}
                    />
                </div>
            );
        }
    }
}