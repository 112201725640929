import React from 'react'
import { TicketProps } from './types/TicketProps'
import { TicketState } from './types/TicketState'
import './resources/Ticket.css'
import { NumberField } from '../../../../shared/Forms/NumberField'

export class Ticket extends React.Component<TicketProps, TicketState> {
  constructor(props: TicketProps) {
    super(props)
    this.state = {
      ticket: {
        concert: props.concert,
        family_quantity: 0,
        individual_quantity: 0,
      },
    }
    this.updateIndividual = this.updateIndividual.bind(this)
    this.updateFamily = this.updateFamily.bind(this)
  }
  updateIndividual(quantity: number): void {
    let newTicket = { ...this.state.ticket }
    newTicket.individual_quantity = quantity
    this.setState({
      ticket: newTicket,
    })
    this.props.onChange && this.props.onChange(newTicket)
  }
  updateFamily(quantity: number): void {
    let newTicket = { ...this.state.ticket }
    newTicket.family_quantity = quantity
    this.setState({
      ticket: newTicket,
    })
    this.props.onChange && this.props.onChange(newTicket)
  }
  render() {
    return (
      <div className='ticket-container'>
        <h2 className='ticket-name center-page'>
          {this.props.concert.name} - <i>{this.props.concert.date}</i>
        </h2>
        <div className='right-align-items right'>
          <div>Quantity</div>
          <NumberField
            label={`Single Admission ($${this.props.concert.individual_price}):`}
            value={this.state.ticket.individual_quantity}
            minValue={0}
            maxValue={255}
            width='50px'
            onChange={this.updateIndividual}
          />
          <NumberField
            label={`Family Pack (5 tickets for $${this.props.concert.family_price}):`}
            value={this.state.ticket.family_quantity}
            minValue={0}
            maxValue={255}
            width='50px'
            onChange={this.updateFamily}
          />
        </div>
      </div>
    )
  }
}
