import React from 'react'
import bannerImage from './resources/bannerImage.png'
import './resources/Concerts.css'
import { PageBanner } from '../../shared/PageBanner/PageBanner'
import { ConcertList } from './components/Concerts/ConcertList'
import { ConcertData } from './types/ConcertData'
import { ConcertsProps } from './types/ConcertsProps'
import { ConcertsState } from './types/ConcertsState'

export class Concerts extends React.Component<ConcertsProps, ConcertsState> {
  constructor(props: ConcertsProps) {
    super(props)
    this.state = {
      error: undefined,
      isLoaded: false,
      concerts: [],
    }
  }
  componentDidMount() {
    fetch('https://api.eaglemountainsymphony.org/concerts')
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            concerts: result.Items,
          })
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          })
        }
      )
  }

  compareConcerts(a: ConcertData, b: ConcertData): number {
    return new Date(b.date) > new Date(a.date) ? 1 : -1
  }

  render() {
    const { error, isLoaded, concerts } = this.state
    if (error) {
      return <div className='center-page warning'>Error: {error.message}</div>
    } else if (!isLoaded) {
      return <div className='center-page'>Loading...</div>
    } else {
      return (
        <div>
          <PageBanner
            image={bannerImage}
            title='A Community of Music'
            subtitle='Fostering an environment of artistic culture to improve our families,
                    friends and neighborhoods throughout our city'
          />
          <ConcertList concerts={concerts.sort(this.compareConcerts)} />
        </div>
      )
    }
  }
}
