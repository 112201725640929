import React from 'react'
import './resources/SectionHeader.css'
import { SectionHeaderProps } from './types/SectionHeaderProps'

export const SectionHeader: React.FunctionComponent<SectionHeaderProps> = ({
  title,
  description,
}: SectionHeaderProps) => (
  <div className='row'>
    <h1 className='section-title'>{title}</h1>
    <p className='section-description'>{description}</p>
  </div>
)
