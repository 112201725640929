import React from 'react'
import './resources/PageBanner.css'
import { PageBannerProps } from './types/PageBannerProps'

export const PageBanner: React.FunctionComponent<PageBannerProps> = ({
  image,
  title,
  subtitle,
}: PageBannerProps) => (
  <div className='banner-container' style={{ backgroundImage: `url(${image})` }}>
    <div className='banner-text-overlay'>
      <h1 className='banner-title'>{title}</h1>
      <p className='banner-subtitle'>{subtitle}</p>
    </div>
  </div>
)
