import React from 'react'
import { SectionHeader } from '../../../../shared/SectionHeader/SectionHeader'
import './resources/Symphony.css'
import { InstrumentSection } from './InstrumentSection'
import { SymphonyState } from './types/SymphonyState'
import { SymphonyProps } from './types/SymphonyProps'
import { SymphonyMemberType } from './types/SymphonyMemberType'
import { InstrumentGroup } from './types/InstrumentGroup'

export class Symphony extends React.Component<SymphonyProps, SymphonyState> {
  constructor(props: SymphonyProps) {
    super(props)
    this.state = {
      error: undefined,
      isLoaded: false,
      members: [],
    }
  }

  componentDidMount() {
    fetch('https://api.eaglemountainsymphony.org/musicians')
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            members: result.Items,
          })
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          })
        }
      )
  }

  splitByInstrument(members: SymphonyMemberType[]): InstrumentGroup[] {
    let instrumentGroups: InstrumentGroup[] = []
    members.forEach((member) => {
      let group =
        instrumentGroups.find((a) => a.instrument === member.instrument) ||
        instrumentGroups[
          instrumentGroups.push({
            instrument: member.instrument,
            members: [],
          }) - 1
        ]
      group.members.push(member)
    })
    instrumentGroups.sort(this.compareInstruments).forEach((group) => {
      group.members.sort(this.compareMembers)
    })
    return instrumentGroups
  }

  compareInstruments(a: InstrumentGroup, b: InstrumentGroup): number {
    return a.instrument === 'Conductor'
      ? -1
      : b.instrument === 'Conductor'
      ? 1
      : b.instrument > a.instrument
      ? -1
      : 1
  }

  compareMembers(a: SymphonyMemberType, b: SymphonyMemberType): number {
    return a.is_section_leader
      ? -1
      : b.is_section_leader
      ? 1
      : a.member_name > b.member_name
      ? 1
      : b.member_name > a.member_name
      ? -1
      : 0
  }

  render() {
    const { error, isLoaded, members } = this.state
    if (error) {
      return <div>Error: {error.message}</div>
    } else if (!isLoaded) {
      return <div>Loading...</div>
    } else {
      return (
        <div>
          <SectionHeader
            title='Meet the Orchestra'
            description='Our musicians are the heart and soul of the orchestra. 
                        They spend many hours practicing individually and as a group each week 
                        to prepare for several exciting performances throughout the year.'
          />
          <div className='row'>
            <div className='member-list'>
              {this.splitByInstrument(members).map((group) => (
                <InstrumentSection
                  key={group.instrument}
                  instrumentName={group.instrument}
                  members={group.members}
                />
              ))}
            </div>
            <div className='member-list-key'>* = section leader</div>
          </div>
        </div>
      )
    }
  }
}
