import React from 'react'
import './resources/AuditionRequestFailureResponse.css'
import { AuditionRequestFailureResponseProps } from './types/AuditionRequestFailureResponseProps'

export const AuditionRequestFailureResponse: React.FunctionComponent<AuditionRequestFailureResponseProps> = ({
  state,
}: AuditionRequestFailureResponseProps) => (
  <div className='row center-page'>
    <h2 className='warning center-page'>Something went wrong</h2>
    <p>
      We were unable to send your audition request due to an error on our server but don't fret. You can still
      submit your audition request by clicking{' '}
      <a
        href={
          `mailto:eaglemountainsymphony@gmail.com?subject=Audition%20Request&body=` +
          encodeURI(`
            Name: ${state.name}
            Instrument: ${state.instrument}
            Email: ${state.email}
            Phone: ${state.phone}
            Experience: ${state.experience}`)
        }>
        this link
      </a>{' '}
      to send an email from your favorite email client
    </p>
  </div>
)
