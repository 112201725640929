import React from 'react'
import './resources/DonateButton.css'
import { DonateButtonProps } from './types/DonateButtonProps'

const _payPalUrl = 'https://www.paypal.com/donate'

export const DonateButton: React.FunctionComponent<DonateButtonProps> = ({
  PaypalButtonId,
}: DonateButtonProps) => (
  <div className='donate-button'>
    <form action={_payPalUrl} method='POST' target='_top'>
      <div>
        <input type='hidden' name='hosted_button_id' value={PaypalButtonId} />
        <input
          type='image'
          src='https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif'
          name='submit'
          title='PayPal - The safer, easier way to pay online!'
          alt='Donate with PayPal'
        />
        <img alt='' src='https://www.paypal.com/en_US/i/scr/pixel.gif' width='1' height='1' />
      </div>
    </form>
  </div>
)
