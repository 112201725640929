import React from 'react'
import './resources/BoardMember.css'
import { BoardMemberType } from './types/BoardMemberType'

export const BoardMember: React.FunctionComponent<BoardMemberType> = ({
  member_id,
  member_name,
  position,
  bio,
}: BoardMemberType) => (
  <div className='board'>
    <div className='board-photo'>
      <img src={`/board_images/${member_id}.jpg`} alt={member_name} />
    </div>
    <div className='board-details'>
      <div className='board-info'>
        {position}: {member_name}
      </div>
      <div className='board-bio'>{bio}</div>
    </div>
  </div>
)
