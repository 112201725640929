import React from 'react'
import './resources/Concert.css'
import { ConcertProps } from './types/ConcertProps'

export const Concert: React.FunctionComponent<ConcertProps> = ({
  concert_id,
  name,
  brief,
  sellTickets,
  showReduced,
  showProgram,
}: ConcertProps) => (
  <div className='row'>
    <div className='concert'>
      <img src={`/concerts/${concert_id}/banner.png`} alt={name} width={showReduced ? '70%' : '100%'} />
    </div>
    <div className='concert-brief'>{brief}</div>
    <div className='concert-buttons'>
      {sellTickets ? (
        <a href='/tickets'>
          <button className='ticket-link'>Buy tickets</button>
        </a>
      ) : (
        <></>
      )}
      {showProgram ? (
        <a href={`/concerts/${concert_id}/program.pdf`} target='_blank' rel={'noreferrer'}>
          <button className='program-link'>View Program</button>
        </a>
      ) : (
        <></>
      )}
    </div>
    <div className='vertical-space-20'>&nbsp;</div>
  </div>
)
