import React from 'react'
import { PromoCodeProps } from './types/PromoCodeProps'
import { PromoCodeState } from './types/PromoCodeState'
import { TextField } from '../../../../shared/Forms/TextField'
import { PromoCodeData } from './types/PromoCodeData'

export class PromoCode extends React.Component<PromoCodeProps, PromoCodeState> {
  constructor(props: PromoCodeProps) {
    super(props)
    this.state = {
      error: undefined,
      isLoaded: false,
      promoCode: undefined,
    }
    this.promoCodeChanged = this.promoCodeChanged.bind(this)
  }
  promoCodeChanged(value: string): void {
    let promoCode: PromoCodeData = {
      promo_code_id: '',
      is_valid: false,
      discount_percent: 0,
    }

    fetch(`https://api.eaglemountainsymphony.org/promo-code/${value}`)
      .then((res) => res.json())
      .then(
        (result) => {
          promoCode = result.Item
          promoCode && this.props.onChange && this.props.onChange(promoCode)
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          promoCode = {
            promo_code_id: error,
            is_valid: false,
            discount_percent: 0,
          }
        }
      )
  }
  render() {
    return (
      <div>
        <div>
          <TextField
            label='Discount Code:'
            value={this.state.promoCode?.promo_code_id}
            width='50px'
            onChange={this.promoCodeChanged}
          />
        </div>
      </div>
    )
  }
}
