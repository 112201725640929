import React from 'react'
import './resources/ConcertList.css'
import { Concert } from './Concert'
import { SectionHeader } from '../../../../shared/SectionHeader/SectionHeader'
import { ConcertListProps } from './types/ConcertListProps'
import { ConcertData } from '../../types/ConcertData'

export class ConcertList extends React.Component<ConcertListProps> {
  concertDateStatus(concert: ConcertData): number {
    let today = new Date(Date.now())
    today.setHours(0, 0, 0, 0)
    let concertDate = new Date(concert.date)
    concertDate.setHours(0, 0, 0, 0)
    if (concertDate < today) {
      return -1
    } else if (concertDate > today) {
      return 1
    } else {
      return 0
    }
  }
  render() {
    const futureConcerts = this.props.concerts.filter((concert) => {
      return this.concertDateStatus(concert) >= 0
    })
    const pastConcerts = this.props.concerts.filter((concert) => {
      return this.concertDateStatus(concert) < 0
    })
    return (
      <div>
        <SectionHeader title='Upcoming Concerts' />
        <div className='row'>
          <div className='concert-list'>
            {futureConcerts.length > 0 ? (
              futureConcerts.map((concert) => (
                <Concert
                  key={concert.concert_id}
                  concert_id={concert.concert_id}
                  name={concert.name}
                  brief={concert.brief}
                  sellTickets={concert.individual_price > 0}
                  showReduced={false}
                  showProgram={this.concertDateStatus(concert) === 0}
                />
              ))
            ) : (
              <h3 className='center-page'>Stay tuned for information on Upcoming Concerts</h3>
            )}
          </div>
        </div>
        {pastConcerts.length > 0 ? (
          <>
            <SectionHeader title='Past Concerts' />
            <div className='row'>
              <div className='concert-list'>
                {pastConcerts.map((concert) => (
                  <Concert
                    key={concert.concert_id}
                    concert_id={concert.concert_id}
                    name={concert.name}
                    brief={concert.brief}
                    sellTickets={false}
                    showReduced={true}
                    showProgram={true}
                  />
                ))}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    )
  }
}
