import React from 'react'
import './resources/ServiceResponse.css'
import { ServiceResponseProps } from './types/ServiceResponseProps'

export const ServiceResponse: React.FunctionComponent<ServiceResponseProps> = ({
  title,
  message,
  isError,
}: ServiceResponseProps) => (
  <div className='row center-page'>
    <h2 className={isError ? 'warning center-page' : 'center-page'}>{title}</h2>
    <p>{message}</p>
  </div>
)
