import React from 'react'
import './resources/TicketList.css'
import { Ticket } from './Ticket'
import { TicketListProps } from './types/TicketListProps'
import { TicketListState } from './types/TicketListState'
import { TicketData } from '../../types/TicketData'

export class TicketList extends React.Component<TicketListProps, TicketListState> {
  constructor(props: TicketListProps) {
    super(props)
    let tickets: TicketData[] = []
    props.concerts.forEach((concert) => {
      tickets.push({
        concert: concert,
        individual_quantity: 0,
        family_quantity: 0,
      })
    })
    this.state = {
      tickets: tickets,
    }
    this.ticketChanged = this.ticketChanged.bind(this)
  }

  ticketChanged(newTicket: TicketData): void {
    let newTickets: TicketData[] = []
    this.state.tickets.forEach((oldTicket) => {
      if (newTicket.concert.concert_id === oldTicket.concert.concert_id) {
        newTickets.push(newTicket)
      } else {
        newTickets.push(oldTicket)
      }
    })

    this.setState({
      tickets: newTickets,
    })
    this.props.onChange && this.props.onChange(newTickets)
  }
  render() {
    return (
      <div>
        <div className='row'>
          <div className='concert-list'>
            {this.props.concerts.length > 0 ? (
              this.props.concerts.map((concert) => (
                <Ticket key={concert.concert_id} concert={concert} onChange={this.ticketChanged} />
              ))
            ) : (
              <h3 className='center-page'>
                No concerts are currently open for ticketing. Stay tuned for tickets to go on sale soon for
                upcoming concerts.
              </h3>
            )}
          </div>
        </div>
      </div>
    )
  }
}
