import React from 'react'
import './resources/ContactFailureResponse.css'
import { ContactFailureResponseProps } from './types/ContactFailureResponseProps'

export const ContactFailureResponse: React.FunctionComponent<ContactFailureResponseProps> = ({
  state,
}: ContactFailureResponseProps) => (
  <div className='row center-page'>
    <h2 className='warning center-page'>Something went wrong</h2>
    <p>
      We were unable to send your message due to an error on our server but don't fret. You can still submit
      your message to us via email by clicking{' '}
      <a
        href={
          `mailto:eaglemountainsymphony@gmail.com?` +
          encodeURI(
            `subject=` +
              state.subject +
              `&body=
            Name: ` +
              state.name +
              `
            Message: ` +
              state.message
          )
        }>
        this link
      </a>
      .
    </p>
  </div>
)
