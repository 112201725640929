import React from 'react'
import './resources/Forms.css'
import { TextFieldProps } from './types/TextFieldProps'
import { TextFieldState } from './types/TextFieldState'

export class TextField extends React.Component<TextFieldProps, TextFieldState> {
  constructor(props: TextFieldProps) {
    super(props)
    this.state = {
      value: props.value ? props.value : '',
    }
    this.valueChanged = this.valueChanged.bind(this)
  }

  valueChanged(event: React.FormEvent<HTMLInputElement>): void {
    this.setState({ value: event.currentTarget.value })
    this.props.onChange &&
      this.props.onChange(event.currentTarget.value, this.isValid(event.currentTarget.value))
  }

  isValid(value: string): boolean {
    return !this.props.validator || this.props.validator.test(value)
  }

  render() {
    return (
      <div className='field-layout'>
        <label
          htmlFor='input-field'
          className={this.isValid(this.state.value) ? 'form-input-label' : 'form-input-label warning'}>
          {this.props.label}
        </label>
        <input
          id='input-field'
          type='text'
          placeholder={this.props.placeholder}
          value={this.state.value}
          onChange={this.valueChanged}
          width={this.props.width}
        />
      </div>
    )
  }
}
