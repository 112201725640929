import React from "react";
import { ConcertProps } from "./ConcertProps";

export const Concert: React.FunctionComponent<ConcertProps> = ({ concert_id, name, brief }: ConcertProps) => (
    <div className="row">
        <div className="concert"><img src={`/concerts/${concert_id}/banner.png`} alt={name} width="100%" /></div>
        <div className="concert-brief">{brief}</div>
        <div className="concert-buttons">
            <a href={`/concerts/${concert_id}/program.pdf`} target="_blank" rel={'noreferrer'}><button className="program-link">View Program</button></a>
        </div>
        <div className="vertical-space-20">&nbsp;</div>
    </div>
)