import React from 'react'
import './resources/Forms.css'
import { NumberFieldProps } from './types/NumberFieldProps'
import { NumberFieldState } from './types/NumberFieldState'

export class NumberField extends React.Component<NumberFieldProps, NumberFieldState> {
  constructor(props: NumberFieldProps) {
    super(props)
    this.state = {
      value: props.value,
    }
    this.valueChanged = this.valueChanged.bind(this)
  }

  valueChanged(event: React.FormEvent<HTMLInputElement>): void {
    let newNumber: number = parseFloat(event.currentTarget.value)
    this.setState({ value: newNumber })
    this.props.onChange && this.props.onChange(newNumber)
  }

  render() {
    return (
      <div className='field-layout'>
        <div>
          <label htmlFor='input-field' className='form-input-label'>
            {this.props.label}
          </label>
        </div>
        <div>
          <input
            id='input-field'
            type='number'
            min={this.props.minValue}
            max={this.props.maxValue}
            value={this.state.value}
            onChange={this.valueChanged}
            width={this.props.width}
          />
        </div>
      </div>
    )
  }
}
