import React from 'react'
import './resources/ContactForm.css'
import { SectionHeader } from '../../../../shared/SectionHeader/SectionHeader'
import { ServiceResponse } from '../../../../shared/ServiceResponse/ServiceResponse'
import { TextField } from '../../../../shared/Forms/TextField'
import { TextAreaField } from '../../../../shared/Forms/TextAreaField'
import { Validators } from '../../../../shared/Forms/types/Validators'
import { ContactFailureResponse } from './ContactFailureResponse'
import { ContactFormProps } from './types/ContactFormProps'
import { ContactFormState } from './types/ContactFormState'

const _contactUsUrl = 'https://api.eaglemountainsymphony.org/contact-us'

export class ContactForm extends React.Component<ContactFormProps, ContactFormState> {
  constructor(props: ContactFormProps) {
    super(props)
    this.state = {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
      nameIsValid: false,
      emailIsValid: false,
      phoneIsValid: true,
      subjectIsValid: false,
      messageIsValid: false,
      requestSucceeded: true,
      responseIsError: false,
      responseTitle: '',
      responseMessage: '',
      isValid: true,
      showForm: true,
    }
    this.nameChanged = this.nameChanged.bind(this)
    this.emailChanged = this.emailChanged.bind(this)
    this.phoneChanged = this.phoneChanged.bind(this)
    this.subjectChanged = this.subjectChanged.bind(this)
    this.messageChanged = this.messageChanged.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  messageIsValid(): boolean {
    return (
      this.state.nameIsValid &&
      this.state.phoneIsValid &&
      this.state.emailIsValid &&
      this.state.subjectIsValid &&
      this.state.messageIsValid
    )
  }

  nameChanged(value: string, isValid: boolean): void {
    this.setState({ name: value, nameIsValid: isValid })
  }

  emailChanged(value: string, isValid: boolean): void {
    this.setState({ email: value, emailIsValid: isValid })
  }

  phoneChanged(value: string, isValid: boolean): void {
    this.setState({ phone: value, phoneIsValid: isValid })
  }

  subjectChanged(value: string, isValid: boolean): void {
    this.setState({ subject: value, subjectIsValid: isValid })
  }

  messageChanged(value: string, isValid: boolean): void {
    this.setState({ message: value, messageIsValid: isValid })
  }

  handleSubmit(event: React.FormEvent): void {
    event.preventDefault()
    var message = {
      name: this.state.name,
      phone: this.state.phone,
      email: this.state.email,
      subject: this.state.subject,
      message: this.state.message,
    }
    if (this.messageIsValid()) {
      const requestMetadata = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(message),
      }
      fetch(_contactUsUrl, requestMetadata)
        .then((res) => res.json())
        .then(() => {
          this.setState({
            showForm: false,
            responseIsError: false,
            responseTitle: 'Thank you for your message!',
            responseMessage:
              'Your feedback is very important to us. Thank you for taking the time to share it.',
          })
        })
        .catch(() => {
          this.setState({ requestSucceeded: false, showForm: false })
        })
    } else {
      this.setState({
        responseIsError: true,
        responseTitle: 'Did you mean to do that?',
        responseMessage: 'Please double-check that you filled out all fields correctly.',
      })
    }
  }

  render() {
    return (
      <div>
        <div className='center-page'>
          <SectionHeader title='Send Us a Message' />
          {this.state.requestSucceeded ? (
            <ServiceResponse
              title={this.state.responseTitle}
              message={this.state.responseMessage}
              isError={this.state.responseIsError}
            />
          ) : (
            <ContactFailureResponse state={this.state} />
          )}
          {this.state.showForm ? (
            <form onSubmit={this.handleSubmit}>
              <TextField
                label='Name:'
                placeholder='Full name…'
                value={this.state.name}
                onChange={this.nameChanged}
                validator={Validators.stringExistsValidator}
              />
              <TextField
                label='Phone: (optional)'
                placeholder='Phone number…'
                value={this.state.phone}
                onChange={this.phoneChanged}
              />
              <TextField
                label='Email:'
                placeholder='Email address…'
                value={this.state.email}
                onChange={this.emailChanged}
                validator={Validators.emailValidator}
              />
              <TextField
                label='Subject:'
                placeholder='Message Subject…'
                value={this.state.subject}
                onChange={this.subjectChanged}
                validator={Validators.stringExistsValidator}
              />
              <TextAreaField
                label='Message:'
                placeholder='What would you like to tell us?'
                value={this.state.message}
                onChange={this.messageChanged}
                validator={Validators.stringExistsValidator}
                cols={40}
                rows={5}
              />
              <div className='vertical-space-20'></div>
              <button type='submit' className='center-page'>
                Send the message
              </button>
            </form>
          ) : (
            ''
          )}
        </div>
        <div className='vertical-space-20'></div>
      </div>
    )
  }
}
