import React from 'react'
import bannerImage from './resources/bannerImage.png'
import './resources/Contact.css'
import { PageBanner } from '../../shared/PageBanner/PageBanner'
import { ContactForm } from './components/ContactForm/ContactForm'

export const Contact: React.FunctionComponent = () => (
  <div>
    <PageBanner
      image={bannerImage}
      title='We want to hear from YOU'
      subtitle='We love to hear your feedback, your ideas and suggestions. You make
        us a better orchestra.'
    />
    <ContactForm />
  </div>
)
